<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      :owner="owner"
      :name="name"
      @history="onHistory"
      @collapse="onCollapse"
    ></FieldHeader>
    <MedicalcodesAutocomplete
      v-if="!disabled && !hideBody"
      :value.sync="codes"
      :model="codeModel"
      :autosaveStatus="status"
      favorites
    ></MedicalcodesAutocomplete>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <MedicalcodesAutocomplete
        disabled
        :value.sync="field.data.codes"
        :model="codeModel"
        altStyle
      ></MedicalcodesAutocomplete>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    codeModel() {
      return this.field.name.toLowerCase();
    },
    codes: {
      get() {
        return this.field.data.codes;
      },
      set(value) {
        this.$set(this.field.data, "codes", value);
      },
    },
    //Vue doesn't detect changes if watched value is object.
    //The only workaround is to change object to JSON string.
    //Workaround for $watch:
    codesJson: {
      get() {
        this.$emit('changed:codes')
        return JSON.stringify(this.codes);
      },
    },
  },
  components: {
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    MedicalcodesAutocomplete: () =>
      import("@/components/selects/MedicalcodesAutocomplete"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("codesJson");
    }
  },
};
</script>